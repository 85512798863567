<template>
  <div class="projects">
    <div class="container">
      <div class="page-breadcrumb" v-if="fund">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: `/fund/${fund.id}` }" v-if="fund">{{ getText(fund, 'name') }}</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t('fund.FundPortfolio') }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>

      <div class="card" v-if="fund">
        <div class="card-header">
          {{ getText(fund, 'name') }} <span style="color: #a0a0a0; font-size: 13px">{{ $t('SelectedProjects') }}</span>
        </div>
        <div class="card-content">
          <div class="deadline" v-if="fund.deadlineDate">{{ $t('DeadLine') }} {{ fund.deadlineDate }}</div>
          <el-row>
            <el-col :span="8">{{ $t('fund.fundSize') }}：{{ getText(fund, 'scale') }}</el-col>
            <el-col :span="8">{{ $t('fund.ClosingDate') }}：{{ fund.establish }}</el-col>
            <el-col :span="8">{{ $t('fund.FundTerm') }}：{{ getText(fund, 'years') }}</el-col>
            <el-col :span="8">{{ $t('fund.registeredLocation') }}：{{ getText(fund, 'registerAddress') }}</el-col>
            <el-col :span="8">{{ $t('fund.DeployedCapital') }}：{{ fund.totalInvest }}</el-col>
            <el-col :span="8">{{ $t('fund.TotalDistribution') }}：{{ getText(fund, 'totalDispense') }}</el-col>
            <el-col :span="8">{{ $t('fund.portfoliosNumber') }}：{{ fund.projectNumber }}</el-col>
            <el-col :span="8" v-if="fund.moic">MOIC：{{ fund.moic }}</el-col>
            <!-- <el-col :span="8" v-if="fund.marketTo">{{ $t('MarkedtoMarket') }}：{{ getText(fund, 'marketTo') }}</el-col> -->
          </el-row>
        </div>
      </div>

      <div class="year-list" v-if="projectList.length > 0">
        <div class="year-item" v-for="item in projectList" :key="item.year">
          <div class="year-title">{{ item.year }}</div>
          <div class="project-list">
            <el-row :gutter="16">
              <el-col :span="8" v-for="project in item.data" :key="project.name">
                <div class="item" @click="toDetail(project.id)">
                  <div class="item-pic">
                    <el-image style="width: 110px; height: 110px" :src="project.logoUrl" fit="cover"></el-image>
                    <div class="custom-field" v-if="project.marketName">{{ getText(project, 'marketName') }}</div>
                  </div>
                  <div class="item-content">
                    <h4>{{ getText(project, 'name') }}</h4>
                    <div class="meta">{{ getText(project.industry, 'name') }}</div>
                    <ul>
                      <li>{{ $t('project.entryRound') }}：{{ getText(project, 'enterRound') }}</li>
                      <li>{{ $t('project.investmentAmount') }}：{{ getText(project, 'investAmount') }}</li>
                      <li>{{ $t('project.investmentDate') }}：{{ project.investTime }}</li>
                    </ul>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'

export default {
  name: 'Projects',
  data() {
    return {
      projectList: [],
      fund: null,
    }
  },
  created() {
    this.getFund()
    this.getInvestedProjects()
  },
  methods: {
    getFund() {
      api.getFund(this.$route.params.id).then((res) => {
        this.fund = res.data
      })
    },
    getInvestedProjects() {
      api.getInvestedProjects(this.$route.params.id).then((res) => {
        this.projectList = res.data
      })
    },
    toDetail(id) {
      this.$router.push(`/project-detail/${id}?fundId=${this.$route.params.id}`)
    },
  },
}
</script>
